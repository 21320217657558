import React, { useEffect, useRef, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";

import { I18nextProvider, useTranslation } from "react-i18next";
import instance from "../../utils/i18n";

import { v4 as uuidv4 } from "uuid";

import Graphic from "@arcgis/core/Graphic";

import { getDirectionsUrl } from "../../utils";
import LocationCard from "../LocationCard";
import { getLocCardDetails } from "../LocationCard/LocationCardHelper";
import { StyledList } from "./List-styled";
import { ListProps } from "./List.types";
import {
  generateSkeletonCards,
  getAllLocations,
  getLocationCount,
  getSomeLocations,
} from "./ListHelper";
import NoResults from "./NoResults";

import { CalciteButton } from "@esri/calcite-components-react";
import "@esri/calcite-components/dist/components/calcite-button";

const List = ({
  isMapView,
  locationsFeatureLayer,
  locationsFeatureLayerInfo,
  filterWhere,
  focalPoint,
  onListLoadError,
  initialSearchBufferDistance,
  onListItemHover,
  onCardClick,
  updateGoogleTranslate,
  fieldLabels,
}: ListProps) => {
  const skeletonCards = generateSkeletonCards();
  const listWrapper = useRef<HTMLUListElement>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const [locations, setLocations] = useState<Graphic[]>([]);
  const [locationCount, setLocationCount] = useState<number>(0);

  const { t, i18n } = useTranslation("components", { i18n: instance });

  const onShowAll = async () => {
    const [lastLoc] = locations.slice(-1);
    const lastLocId = lastLoc.attributes[locationsFeatureLayerInfo.objectIdFieldName];
    if (locationsFeatureLayer) {
      setLoading(true);
      try {
        const newLocations = await getAllLocations(
          t,
          locationsFeatureLayer,
          locationsFeatureLayerInfo.openHoursServiceTypes,
          locationsFeatureLayerInfo.openHoursTimeWindows,
          locationsFeatureLayerInfo.domainFieldNames,
          filterWhere,
          focalPoint
        );
        setLocations(newLocations);

        updateGoogleTranslate();

        setTimeout(() => {
          const selector = `[data-locationid="${lastLocId}"]`;
          const lastLocElement = listWrapper.current?.querySelector(selector);
          lastLocElement?.scrollIntoView();
          (lastLocElement as HTMLElement)?.focus();
        }, 5);
      } catch (error: unknown) {
        onListLoadError?.(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const doUpdate = async () => {
      if (locationsFeatureLayer) {
        setLoading(true);
        try {
          const newLocations = await getSomeLocations({
            t: t,
            featureLayer: locationsFeatureLayer,
            initialBufferDistance: initialSearchBufferDistance || 10000,
            openHoursServiceTypes: locationsFeatureLayerInfo.openHoursServiceTypes,
            domainFieldNames: locationsFeatureLayerInfo.domainFieldNames,
            filterWhere,
            focalPoint,
          });
          setLocations(newLocations);
          updateGoogleTranslate();
        } catch (error: unknown) {
          onListLoadError?.(error);
        } finally {
          setLoading(false);
          setTimeout(updateGoogleTranslate, 300);
        }
      }
    };
    doUpdate();
  }, [
    locationsFeatureLayer,
    filterWhere,
    focalPoint,
    onListLoadError,
    locationsFeatureLayerInfo.domainFieldNames,
    initialSearchBufferDistance,
    locationsFeatureLayerInfo.openHoursServiceTypes,
    updateGoogleTranslate,
  ]);

  useEffect(() => {
    const getCount = async () => {
      if (locationsFeatureLayer) {
        try {
          const count = await getLocationCount(locationsFeatureLayer, filterWhere);
          setLocationCount(count);
        } catch (error: unknown) {
          console.warn("Unable to get the location count", error);
        }
      }
    };
    getCount();
  }, [locationsFeatureLayer, filterWhere]);

  useEffect(() => {
    if (!loading && locations?.length) {
      updateGoogleTranslate();
      // I don't fully understand why english is loaded
      setTimeout(updateGoogleTranslate, 300);
    }
  }, [loading, locations?.length, updateGoogleTranslate]);

  return (
    <I18nextProvider i18n={i18n}>
      <StyledList
        ref={listWrapper}
        isMapView={isMapView}
        data-testid="listWrapper"
        aria-labelledby="listStatus"
      >
        {loading && skeletonCards}
        {!loading &&
          locations?.length > 0 &&
          locations?.map((loc) => {
            const cardDetails = getLocCardDetails({
              loc,
              locationLayerInfo: locationsFeatureLayerInfo,
              locationLayer: locationsFeatureLayer,
              hideDistance:
                focalPoint?.attributes.isHidden ||
                loc.attributes?.address_display === "No" ||
                false,
              t,
              fieldLabels,
              language: i18n.language,
            });
            return (
              <LocationCard
                key={uuidv4()}
                cardType="default"
                directionsLink={getDirectionsUrl(loc, focalPoint)}
                cardDetails={cardDetails}
                locObjId={loc.attributes[locationsFeatureLayerInfo.objectIdFieldName]}
                onClick={onCardClick}
                location={loc}
                hoverHandler={onListItemHover}
              ></LocationCard>
            );
          })}
        {!loading && (!locations || locations.length === 0) && (
          <NoResults message={t("list.noResultsContent")} />
        )}
        {/* This label must be visible so that a change in status will be read aloud in screenreaders */}
        {!loading && locationCount !== 0 && (
          <span id="listFooter">
            <span id="listStatus" aria-live="polite">
              {t("list.loadedResults", {
                numLocs: locations?.length || 0,
                totalLocs: locationCount || 0,
              })}
            </span>
            {locationCount !== locations.length && (
              <CalciteButton
                id="load-all-btn"
                class="lib-neutral-button"
                onClick={onShowAll}
                appearance="outline"
                iconStart="list-show-all"
                color="neutral"
              >
                {t("list.loadAll")}
              </CalciteButton>
            )}
          </span>
        )}
      </StyledList>
    </I18nextProvider>
  );
};
export default List;
