// Framework and third-party non-ui
import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Helpers/utils/modules
import { useAppContext } from "../../contexts/AppContext";
import LocationsPage from "../../pages/Locations";
import LandingPage from "../../pages/Landing";

import { AppTheme, LayerSourceInfo, LocatorSourceInfo, Utils } from "nyc-component-lib";

import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

import "@arcgis/core/assets/esri/themes/light/main.css";
import "@esri/calcite-components/dist/calcite/calcite.css";

const App = () => {
  const {
    updateLocationsFeatureLayer,
    appConfig,
    updateAppConfig,
    isActive,
    eventStatus,
    evacZones,
    alternateAppTitle,
    alternateBannerText,
    alternateDescriptionText,
    layoutDir,
    updateLayoutDir,
    isEmergency,
  } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const appId = window.appConfig.appId || "error";

  const getAppTitle = (): string => {
    const appTitleObj = t(`app.info.appTitle.${appId}`, {
      returnObjects: true,
    });
    for (const [k, v] of Object.entries(appTitleObj)) {
      if (k === eventStatus) {
        return v;
      }
    }

    return t(`app.info.appTitle.${appId}.default`);
  };

  useEffect(() => {
    // Set top level title
    const headElement = document.getElementsByTagName("head");
    const titleElement = document.createElement("title");
    titleElement.innerText = getAppTitle();
    headElement[0].appendChild(titleElement);

    updateAppConfig(window.appConfig);

    const locationsFeatureLayer = new FeatureLayer({
      url: window.activeMapConfig.loc_layer_url,
    });
    updateLocationsFeatureLayer(locationsFeatureLayer);

    if (window.appConfig.useGoogleTranslate) {
      window.googleTranslateElementInit();
    } else {
      console.log(i18n.language);

      Utils.setLanguage(i18n.language);
      updateLayoutDir(i18n.language);
    }
    // we only want this to happen first load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isActive) {
      if (location.pathname.includes("deactivated")) {
        navigate("/");
      }
    } else {
      if (!location.pathname.includes("deactivated")) {
        navigate("deactivated");
      }
    }
  }, [isActive, location, navigate]);

  const viewCommonProps = {
    appTitle: getAppTitle(),
    sourceInfos: [
      {
        type: "layer",
        url: window.activeMapConfig.loc_layer_url,
        fieldName: appConfig?.search.searchLocFieldName,
      } as LayerSourceInfo,
      {
        type: "locator",
        url: appConfig?.search.geoCodeURL,
      } as LocatorSourceInfo,
    ],
    eventState: isEmergency ? "active" : eventStatus,
    evacZones: evacZones,
    alternateAppTitle: alternateAppTitle,
    alternateBannerText: alternateBannerText,
    alternateDescriptionText: alternateDescriptionText,
    emergency: isEmergency,
  };

  if (appConfig) {
    const fieldLabels = t(`app.fieldLabels.${appConfig.appId}`, {
      returnObjects: true,
    });

    return (
      <div
        data-testid="App"
        role="main"
        aria-label={t(`app.info.ariaAppTitle.${appId}`)}
        style={{ height: "inherit" }}
        dir={layoutDir}
      >
        <AppTheme />
        <Routes>
          <Route path="/" element={<LandingPage {...viewCommonProps} deactivated={false} />} />
          <Route
            path="/deactivated"
            element={<LandingPage {...viewCommonProps} deactivated={true} />}
          />
          <Route
            path="/locations"
            element={
              <LocationsPage
                {...viewCommonProps}
                fieldLabels={fieldLabels}
                layoutDir={layoutDir}
              ></LocationsPage>
            }
          />
          {/* React Router v6 does not support optional params, recommendation is to duplicate path */}
          <Route
            path="/locations/:locationId"
            element={
              <LocationsPage
                {...viewCommonProps}
                fieldLabels={fieldLabels}
                layoutDir={layoutDir}
              ></LocationsPage>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default App;
