import Geometry from "@arcgis/core/geometry/Geometry";
import Point from "@arcgis/core/geometry/Point";
import { webMercatorToGeographic } from "@arcgis/core/geometry/support/webMercatorUtils";
import Graphic from "@arcgis/core/Graphic";
import Polyline from "@arcgis/core/geometry/Polyline";
import { geodesicLength } from "@arcgis/core/geometry/geometryEngine";

import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import CodedValueDomain from "@arcgis/core/layers/support/CodedValueDomain";

const getCoordinatesStringFromPoint = (point: Point) => {
  const latLongPoint = geometryToGeographic(point) as Point;
  return `${latLongPoint.y},${latLongPoint.x}`;
};

const geolocationToPointGraphic = (geolocation: GeolocationPosition) => {
  const { latitude, longitude } = geolocation.coords;
  return new Graphic({ geometry: new Point({ latitude, longitude }) });
};

const geometryToGeographic = (pointGeometry: Geometry) => {
  // attempt to convert a point geometry into WKID 4326 if its spatial reference is in Web Mercator
  // NOTE: only supports Web Mercator to geographic WKID 4326 conversion
  let geographicPointGeometry = pointGeometry.clone();
  if (pointGeometry.spatialReference.isWebMercator) {
    geographicPointGeometry = webMercatorToGeographic(pointGeometry, false);
  }
  return geographicPointGeometry;
};

const updateDomainFields = (
  locations: Graphic[],
  featureLayer: FeatureLayer,
  domainFieldNames: string[]
) => {
  const domains = domainFieldNames.reduce(
    (domainObj: Map<string, CodedValueDomain>, fieldName: string) => {
      const d = featureLayer.getFieldDomain(fieldName);
      if (d?.type === "coded-value") {
        domainObj.set(fieldName, d as CodedValueDomain);
      } else {
        console.warn(`Unexpected domain for field ${fieldName} found: `, d);
      }
      return domainObj;
    },
    new Map()
  );
  locations.forEach((location: Graphic) => {
    domainFieldNames.forEach((fieldName) => {
      const domainCode = location.attributes[fieldName];
      location.attributes[fieldName] = domains.get(fieldName)?.getName(domainCode) || domainCode;
    });
  });
};

const getDirectionsUrl = (loc: Graphic, focalPoint?: Graphic) => {
  const directionsUrl = new URL("https://www.google.com/maps/dir/");
  directionsUrl.searchParams.append("api", "1");
  directionsUrl.searchParams.append(
    "destination",
    getCoordinatesStringFromPoint(loc.geometry as Point)
  );
  if (focalPoint) {
    directionsUrl.searchParams.append(
      "origin",
      getCoordinatesStringFromPoint(focalPoint.geometry as Point)
    );
  }
  return directionsUrl.href;
};

const onOpenDirections = (loc: Graphic, focalPoint?: Graphic) => {
  const directionsUrl = getDirectionsUrl(loc, focalPoint);
  window.open(directionsUrl, "_blank");
};

const geolocationToPoint = (geolocation: GeolocationPosition): Point => {
  const { latitude, longitude } = geolocation.coords;
  return new Point({ y: latitude, x: longitude });
};

const getDistanceFromFocalPoint = (focalPoint: Graphic, location: Graphic) => {
  if (
    !focalPoint?.geometry ||
    !location?.geometry ||
    location?.attributes?.address_display === "No"
  ) {
    // If either point doesn't have a geometry, return null
    // OR
    // if the location has address_display set to "No", return null
    return null;
  }

  const polyline = new Polyline({
    paths: [
      [
        [(focalPoint.geometry as Point).longitude, (focalPoint.geometry as Point).latitude],
        [(location.geometry as Point).longitude, (location.geometry as Point).latitude],
      ],
    ],
    spatialReference: {
      wkid: 4326,
    },
  });

  return geodesicLength(polyline, "miles");
};

const roundToTwo = (num: number | null) => {
  if (!num && num !== 0) {
    return null;
  }
  return Math.round(num * 100) / 100;
};

export {
  getCoordinatesStringFromPoint,
  geolocationToPointGraphic,
  geometryToGeographic,
  updateDomainFields,
  getDirectionsUrl,
  onOpenDirections,
  geolocationToPoint,
  getDistanceFromFocalPoint,
  roundToTwo,
};
