import React from "react";
import { ListItemHoverEvent, LocationTitleIcons, LocationTextLine, ServiceOption } from "../shared";
import {
  StyledCardLink,
  StyledCardTitle,
  StyledContent,
  StyledDetailSpan,
  StyledLocationCard,
  StyledPhoneLink,
  StyledServices,
  StyledWebsiteLink,
} from "./LocationCard-styled";
import { LocationCardProps } from "./LocationCard.types";

import { I18nextProvider, useTranslation } from "react-i18next";
import instance from "../../utils/i18n";

import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";

import Graphic from "@arcgis/core/Graphic";
import { CalciteButton, CalciteIcon } from "@esri/calcite-components-react";
import "@esri/calcite-components/dist/components/calcite-button";

const LocationCard = ({
  cardType,
  cardDetails,
  directionsLink,
  locObjId,
  location,
  hoverHandler,
  onClick,
}: LocationCardProps) => {
  const { t, i18n } = useTranslation("components", { i18n: instance });

  const cardElementType = cardType === "popup" ? "div" : "li";
  const statusColor = (open: boolean) => {
    return open ? "var(--lib-primary-green)" : "var(--lib-primary-red)";
  };

  const raiseHover = (isHovering: boolean, itemKey: string, location: Graphic): void => {
    const e: ListItemHoverEvent = {
      isHovering: isHovering,
      itemKey: itemKey,
      location: location,
    };

    if (hoverHandler) hoverHandler(e);
  };

  return (
    <I18nextProvider i18n={i18n}>
      {/* return as li when rendered in list, div when rendered in popup */}
      <StyledLocationCard
        as={cardElementType}
        key={cardDetails.locFID}
        data-locationid={cardDetails.locFID}
        onMouseEnter={() => {
          raiseHover(true, cardDetails.locFID, location);
        }}
        onMouseLeave={() => {
          raiseHover(false, cardDetails.locFID, location);
        }}
        onKeyPress={(e: React.KeyboardEvent) => {
          if (e.key === "Enter" && locObjId) {
            onClick(locObjId);
          }
        }}
        aria-labelledby={`cardLink_${locObjId}`}
      >
        <StyledContent>
          <div className="card-header">
            <StyledCardTitle className="card-title">
              {locObjId ? (
                <StyledCardLink
                  id={`cardLink_${locObjId}`}
                  title={t("locCard.viewDetailsFor", { locName: cardDetails.locTitle })}
                  aria-label={t("locCard.viewDetailsFor", { locName: cardDetails.locTitle })}
                  onClick={() => onClick(locObjId)}
                  tabIndex={0}
                >
                  {cardDetails.locTitle}
                </StyledCardLink>
              ) : (
                cardDetails.locTitle
              )}
              <LocationTitleIcons
                isAccessible={cardDetails.locIsAccessible}
                isPetFriendly={cardDetails.locIsPetFriendly}
              />
            </StyledCardTitle>
            <LocationTextLine>
              {cardDetails.locSubtitle && (
                <StyledDetailSpan>{cardDetails.locSubtitle}</StyledDetailSpan>
              )}
            </LocationTextLine>
            <LocationTextLine>
              {cardDetails.locType && <StyledDetailSpan>{cardDetails.locType}</StyledDetailSpan>}
              {cardDetails.locDist && <StyledDetailSpan>{cardDetails.locDist}</StyledDetailSpan>}
            </LocationTextLine>
            <LocationTextLine dir="ltr">
              {cardDetails.locAddress && (
                <StyledDetailSpan>{cardDetails.locAddress}</StyledDetailSpan>
              )}
            </LocationTextLine>
            <LocationTextLine>
              {cardDetails.locAddress2 && (
                <StyledDetailSpan>{cardDetails.locAddress2}</StyledDetailSpan>
              )}
            </LocationTextLine>
            <LocationTextLine dir="ltr">
              {cardDetails.locStatus &&
                (cardDetails.locStatus.isOpen === true ||
                  cardDetails.locStatus.isOpen === false) && (
                  <StyledDetailSpan
                    dir="ltr"
                    style={
                      {
                        "--span-color": statusColor(cardDetails.locStatus.isOpen),
                      } as React.CSSProperties
                    }
                  >
                    {cardDetails.locStatus.isOpen === true && t("locCard.isOpen")}
                    {cardDetails.locStatus.isOpen === false && t("locCard.isClosed")}
                  </StyledDetailSpan>
                )}
              {cardDetails.locStatus?.hoursNote && (
                <StyledDetailSpan dir="ltr">{cardDetails.locStatus.hoursNote}</StyledDetailSpan>
              )}
              {cardDetails.locPhone?.includes(" ext.") ? (
                <StyledPhoneLink
                  dir="ltr"
                  aria-label={cardDetails.locPhone}
                  href={`tel: ${cardDetails.locPhone.slice(
                    0,
                    cardDetails.locPhone.indexOf(" ext.") - 1
                  )}`}
                >
                  {cardDetails.locPhone}
                </StyledPhoneLink>
              ) : (
                <StyledPhoneLink
                  aria-label={cardDetails.locPhone}
                  href={`tel: ${cardDetails.locPhone}`}
                >
                  {cardDetails.locPhone}
                </StyledPhoneLink>
              )}
              {cardDetails.locWebsite && (
                <StyledWebsiteLink
                  aria-label={cardDetails.locWebsite}
                  href={cardDetails.locWebsite}
                  target="_blank"
                  rel="noreferrer"
                  dir="ltr"
                >
                  <CalciteIcon icon="launch" scale="s"></CalciteIcon>
                  {t("locCard.website")}
                </StyledWebsiteLink>
              )}
            </LocationTextLine>
          </div>
          {cardDetails.locServices && (
            <StyledServices className="card-services">
              {cardDetails.locServices.map((service) => (
                <ServiceOption key={uuidv4()} label={service}></ServiceOption>
              ))}
            </StyledServices>
          )}
          {cardDetails.locMessage && (
            <StyledDetailSpan
              style={{ "--span-color": "var(--lib-primary-green)" } as React.CSSProperties}
            >
              {cardDetails.locMessage}
            </StyledDetailSpan>
          )}
        </StyledContent>
        <CalciteButton
          class="lib-neutral-button directions-btn"
          appearance="outline"
          color="neutral"
          iconStart="road-sign"
          scale="l"
          round
          label={t("locCard.openDirectionsFor", { locName: cardDetails.locTitle })}
          href={directionsLink}
          target="_blank"
          disabled={cardDetails.locAddress === null ? true : undefined}
        />
        {locObjId && (
          <div className="chevron">
            <FontAwesomeIcon
              aria-hidden={true}
              icon={faChevronRight}
              style={{ fontSize: 16, color: "var(--lib-secondary-gray)" }}
            ></FontAwesomeIcon>
          </div>
        )}
      </StyledLocationCard>
    </I18nextProvider>
  );
};

export default LocationCard;
