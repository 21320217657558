/*
  LocationTextLine is a utility component formatting child elements with separator characters
*/

import React from "react";
import { Children } from "react";
import { LocTextLineProps } from "./shared.types";
import styled from "styled-components";

const StyledP = styled.p`
  font-size: inherit;
  > :not(:last-child) {
    :after {
      content: "•";
      color: var(--lib-secondary-gray);
      display: inline-block;
      padding: 0 0.5rem 0 0.5rem;
    }
  }

  text-align: var(--rtl-text-align);
`;

const LocationTextLine = ({ children, dir }: LocTextLineProps) => {
  const childArr = children ? Children.toArray(children) : [];
  return childArr.filter((x) => x !== "").length !== 0 ? (
    <StyledP dir={dir}>{children}</StyledP>
  ) : null;
};

export default LocationTextLine;
