import React from "react";
import { v4 as uuidv4 } from "uuid";

import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Graphic from "@arcgis/core/Graphic";

import { TFunction } from "react-i18next";

import { StyledHighlightedHoursRow, StyledHoursRow } from "./Details-styled";

//used to construct field names in FormatHrs
const days: string[] = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

const _getLocationById = async function (locFID: number, locationsFeatureLayer: FeatureLayer) {
  const query = {
    objectIds: [locFID],
    outFields: ["*"],
    returnGeometry: true,
    outSpatialReference: {
      wkid: 4326,
    },
  };
  const results = await locationsFeatureLayer.queryFeatures(query);
  const location = results.features[0];

  return location;
};

const reverseString = (strAry: string[]) => {
  return strAry.join("").split(" ").reverse().join(" ");
};

/*
  Formats hours sections:
  - Returns StyledHoursRow reading "No information available" if no hours info for location
  - Returns StyledHoursRow for each weekday the location is open
    - Row contains TH with the day, TD with list of times the location is open on that day (up to 3)
    - Returns StyledHighlightedHoursRow if weekday matches today's date
*/
function formatHrs(
  loc: Graphic,
  locType: string,
  dayIndex: number,
  layoutDir: string,
  t: TFunction
) {
  const styledHrRows = days.map((day, index) => {
    /* 
      Each location may have up to 3 open windows per day
      Assumes each valid opening time matches a valid closing time

      Example: 
      loc[fp_sun_open1] ? `loc[fp_sun_open1] - loc[fp_sun_close1]` : "" 
      `3:00 - 5:00`
    */

    const hrs: JSX.Element[] = [];
    for (let i = 1; i < 4; i++) {
      if (loc.attributes[`${locType}_${day}_open${i}`]) {
        if (layoutDir === "ltr") {
          hrs.push(
            <p key={uuidv4()}>
              {loc.attributes[`${locType}_${day}_open${i}`]
                ? `${loc.attributes[`${locType}_${day}_open${i}`]} - `
                : ""}
              {loc.attributes[`${locType}_${day}_close${i}`]
                ? `${loc.attributes[`${locType}_${day}_close${i}`]}`
                : ""}
            </p>
          );
        } else if (layoutDir === "rtl") {
          const reversedString = reverseString([
            loc.attributes[`${locType}_${day}_open${i}`]
              ? `${loc.attributes[`${locType}_${day}_open${i}`]} - `
              : "",
            loc.attributes[`${locType}_${day}_close${i}`]
              ? `${loc.attributes[`${locType}_${day}_close${i}`]}`
              : "",
          ]);
          hrs.push(<p key={uuidv4()}>{reversedString}</p>);
        }
      }
    }

    const hasHours =
      (loc.attributes[`${locType}_${day}`] && loc.attributes[`${locType}_${day}`] !== "closed") ||
      hrs.length;

    const closedElement = <p key={uuidv4()}>{t("details.closed")}</p>;
    const dayStrings = t("details.dayNames", { returnObjects: true });

    return (
      <StyledHoursRow key={uuidv4()} as={dayIndex === index ? StyledHighlightedHoursRow : "tr"}>
        <th key={uuidv4()}>{dayStrings[index]}</th>
        <td key={uuidv4()}>{hasHours ? hrs : closedElement}</td>
      </StyledHoursRow>
    );
  });
  // Indicate if all "locType_day" fields are null
  const noHrs: JSX.Element = (
    <StyledHoursRow>
      <th>{t("details.noInfo")}</th>
    </StyledHoursRow>
  );
  return styledHrRows.every((element) => element === null) ? noHrs : styledHrRows;
}

export { _getLocationById, formatHrs };
