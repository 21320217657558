import MapView from "@arcgis/core/views/MapView";
import LayerView from "@arcgis/core/views/layers/LayerView";
import FeatureLayerView from "@arcgis/core/views/layers/FeatureLayerView";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Graphic from "@arcgis/core/Graphic";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Extent from "@arcgis/core/geometry/Extent";
import Query from "@arcgis/core/rest/support/Query";
import { Utils } from "../..";

const mapPinSymbol = new PictureMarkerSymbol({
  width: "24px",
  height: "32px",
  yoffset: "20px",
});

const SELECTED_LOCATION_LAYER_ID = "selected-location-graphics-layer";

const updateFilterOnMapView = async (
  mapView: MapView,
  layerUrl: string,
  where: string | undefined
) => {
  const lv = await _waitForLayerView({ mapView, url: layerUrl });
  (lv as FeatureLayerView).layer.definitionExpression = where || "1=1";
};

const _matchLayerUrl = (lv: LayerView, layerUrl: string) => {
  return (
    (lv.layer.type === "feature" &&
      (lv.layer as FeatureLayer).url?.toLowerCase() === layerUrl?.toLowerCase()) ||
    (lv.layer as FeatureLayer).url?.toLowerCase() ===
      layerUrl.substring(0, layerUrl.lastIndexOf("/"))?.toLowerCase()
  );
};

const goToLocations = async ({
  mapView,
  layerUrl,
  features,
  updateMapHighlight,
  objectIdFieldname,
  showPopup,
}: {
  mapView: MapView;
  layerUrl: string;
  features: Graphic[];
  updateMapHighlight: (x?: __esri.Handle) => void;
  objectIdFieldname: string;
  showPopup?: boolean;
}) => {
  try {
    if (!features || features.length === 0 || !mapView) {
      return;
    }
    mapView.when(async () => {
      // If in the future there are multiple matching layers (as we had briefly) the highlight state logic will need to be updated
      const fLayerView = (await _waitForLayerView({
        mapView,
        url: layerUrl,
      })) as FeatureLayerView;
      // If location is a feature in the feature layer (rather than an address search or geolocate)
      const fid = features?.[0].attributes?.[objectIdFieldname];
      const newHighlight = fid
        ? fLayerView.highlight(features?.[0].attributes[objectIdFieldname])
        : undefined;
      updateMapHighlight(newHighlight); // update or clear highlight
      if (showPopup) {
        features.forEach((f) => (f.popupTemplate = fLayerView.layer.popupTemplate));
        mapView.popup.open({ features, location: features[0].geometry });
      }

      return await mapView.goTo({ target: features, zoom: 14 });
    });
  } catch (error) {
    console.warn(error);
  }
};

const goToLocationAndShowPin = async ({
  mapView,
  layerUrl,
  pinPoint,
  pinImageUrl,
  updateMapHighlight,
  objectIdFieldname,
}: {
  mapView: MapView;
  layerUrl: string;
  pinPoint?: Graphic;
  pinImageUrl: string;
  updateMapHighlight: (x?: __esri.Handle) => void;
  objectIdFieldname: string;
}) => {
  if (mapView) {
    // Zoom the map to the selected location and show pin
    await goToLocations({
      mapView,
      layerUrl,
      features: pinPoint ? [pinPoint] : [],
      updateMapHighlight,
      objectIdFieldname,
    });
    _showMapPin(mapView, pinImageUrl, pinPoint);
  }
};

const _showMapPin = async (mapView: MapView, pinImageUrl: string, feature?: Graphic) => {
  const graphicsLayerView = await _waitForLayerView({ mapView, id: SELECTED_LOCATION_LAYER_ID });
  (graphicsLayerView?.layer as GraphicsLayer).graphics.removeAll();
  if (feature && !feature.attributes.isHidden) {
    // add a temporary graphic with the pin symbol
    const pinGraphic = feature.clone();
    mapPinSymbol.url = pinImageUrl;
    pinGraphic.symbol = mapPinSymbol;
    (graphicsLayerView?.layer as GraphicsLayer).graphics.add(pinGraphic);
  }
};

const _waitForLayerView = async ({
  mapView,
  id,
  url,
}: {
  mapView: MapView;
  id?: string;
  url?: string;
}): Promise<__esri.LayerView> => {
  return new Promise((resolve, reject) => {
    try {
      // PART 1: set up watcher for layer creation events, in case layer hasn't been added yet
      const handler = mapView.on("layerview-create", (evt) => {
        if ((id && evt.layer.id === id) || (url && _matchLayerUrl(evt.layerView, url))) {
          handler.remove();
          resolve(evt.layerView);
        }
      });
      // PART 2: check if the layer is already in the map
      const matchingLayerView = mapView.allLayerViews.find((lv: LayerView) => {
        if (url) return _matchLayerUrl(lv, url);
        else if (id) return lv.layer.id === id;
        return false;
      });
      if (matchingLayerView) {
        handler.remove();
        resolve(matchingLayerView);
      }
    } catch (error) {
      reject(error);
    }
  });
};

const showHighlight = async ({
  mapView,
  layerUrl,
  features,
  pan = false,
  updateMapHighlight,
}: {
  mapView: MapView;
  layerUrl: string;
  features: Graphic[];
  pan?: boolean;
  updateMapHighlight: (x?: __esri.Handle) => void;
}) => {
  try {
    if (features?.length && mapView) {
      mapView.when(async () => {
        // If in the future there are multiple matching layers (as we had briefly) the highlight state logic will need to be updated
        const layerView = await _waitForLayerView({ mapView, url: layerUrl });
        if (features.length !== 0) {
          const feature = features[0];
          const newHighlight = (layerView as FeatureLayerView)?.highlight(feature);
          if (pan) mapView.goTo(feature);
          updateMapHighlight(newHighlight);
        } else {
          updateMapHighlight();
        }
      });
    }
  } catch (error) {
    console.warn(error);
  }
};

const centerOnGeolocation = async ({
  mapView,
  geoLocation,
}: {
  mapView: MapView;
  geoLocation: GeolocationPosition;
}) => {
  mapView.when(async () => {
    const ptGraphic = Utils.geolocationToPointGraphic(geoLocation);
    mapView.center = ptGraphic.geometry as __esri.Point;
  });
};

const zoomToLayerExtent = async ({
  mapView,
  featureLayer,
}: {
  mapView: MapView;
  featureLayer?: __esri.FeatureLayer;
}) => {
  try {
    if (mapView) {
      mapView.when(async () => {
        featureLayer?.when(() => {
          featureLayer.queryExtent().then((results) => {
            mapView.goTo(results.extent);
          });
        });
      });
    }
  } catch (error) {
    console.warn(error);
  }
};

const queryLayerExtent = async ({
  featureLayer,
  where,
}: {
  featureLayer?: __esri.FeatureLayer;
  where: string;
}) => {
  await featureLayer?.when();
  const results = await featureLayer?.queryExtent(new Query({ where }));
  return results?.extent as Extent;
};

export {
  updateFilterOnMapView,
  goToLocationAndShowPin,
  goToLocations,
  showHighlight,
  centerOnGeolocation,
  zoomToLayerExtent,
  queryLayerExtent,
  SELECTED_LOCATION_LAYER_ID,
};
