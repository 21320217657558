/* eslint-disable no-extra-boolean-cast */
import { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { v4 as uuidv4 } from "uuid";

import {
  BooleanFilterBanner,
  Details,
  Error as ErrorComponent,
  ErrorTypes,
  Filter,
  FinderSearch,
  HeaderBar,
  List,
  ListItemHoverEvent,
  ListItemHoverHandler,
  Map,
  MapController,
  Message,
  OnSearchCompleteProps,
  PopupUpdateEvent,
  StyledDetailsWrapper,
  Utils,
} from "nyc-component-lib";
import { useAppContext } from "../../contexts/AppContext";
import {
  StyledFabContainer,
  StyledLocationsView,
  StyledListH2,
  StyledFilterH2,
} from "./Locations-styled";
import { LocationPageTypes } from "./Locations.types";

import { CalciteFab } from "@esri/calcite-components-react";
import "@esri/calcite-components/dist/components/calcite-fab";

// InertPolyfill
import "wicg-inert";

import Extent from "@arcgis/core/geometry/Extent";
import Point from "@arcgis/core/geometry/Point";
import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Geometry from "@arcgis/core/geometry/Geometry";

const LocationsPage = ({
  sourceInfos,
  appTitle,
  evacZones,
  alternateAppTitle,
  fieldLabels,
  emergency,
}: LocationPageTypes) => {
  const {
    updateMapView,
    mapView,
    searchTerm,
    updateSearchTerm,
    focalPoint,
    updateFocalPoint,
    filterWhere,
    updateFilterWhere,
    locationsFeatureLayer,
    updateMapHighlight,
    appConfig,
    isEmergency,
    layoutDir,
  } = useAppContext();
  const { locationId } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const locationLayerInfo = useMemo(() => {
    return {
      titleFieldName: appConfig?.fields.locTitleFieldname || "",
      subtitleFieldName: appConfig?.fields.locSubtitleFieldname || "",
      objectIdFieldName: appConfig?.fields.locObjectIDFieldname || "FID",
      addressFieldName: appConfig?.fields.locAddressFieldname,
      address2FieldName: appConfig?.fields.locAddress2Fieldname,
      phoneFieldName: appConfig?.fields.locPhoneFieldname,
      websiteFieldName: appConfig?.fields.locWebsiteFieldname,
      domainFieldNames: appConfig?.fields.locLayerDomainFieldnames,
      serviceTypeFieldNames: appConfig?.fields.locServiceTypeFieldnames || [],
      locMessageFieldNames: appConfig?.fields.locMessageFieldnames,
      openHoursServiceTypes: appConfig?.fields.locOpenHoursServiceTypes || [],
      openHoursTimeWindows: appConfig?.fields.locOpenHoursTimeWindows || [],
      serviceTypeNotes: appConfig?.fields.locServiceTypeNotes || [],
      locTypeFieldName: appConfig?.fields.locTypeFieldname,
      servicesFormat: appConfig?.fields.servicesFormat || ("byvalue" as "byvalue" | "notnull"),
      accessibleFieldName: appConfig?.fields.locAccessibleFieldname || "",
      accessibleFieldValue: appConfig?.fields.locAccessibleFieldValue || "",
      petFriendlyFieldName: appConfig?.fields.locPetFriendlyFieldname || "",
      petFriendlyFieldValue: appConfig?.fields.locPetFriendlyFieldValue || "",
    };
  }, [appConfig?.fields]);

  const zoneFL = useMemo(() => {
    const returnFL = window.activeMapConfig?.zone_layer_url
      ? new FeatureLayer({
          url: window.activeMapConfig?.zone_layer_url,
        })
      : null;

    returnFL
      ?.queryFeatureCount()
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        setError(new Error(t("locations.unknownError")));
        setErrorMessage(ErrorTypes.ZONES);
      });
    return returnFL;
  }, [t]);

  // useTranslate issue with key `app.filter.categoryFilters.appId.arrayIndex.key` getting object array
  const categoryFiltersStringsArray = t(
    `app.filter.categoryFilters.${appConfig?.appId || "foodhelp"}`,
    { returnObjects: true }
  );
  const filterFields =
    appConfig?.filter.categoryFilters?.map((item, idx) => {
      return {
        ...item,
        ...categoryFiltersStringsArray[idx],
      };
    }) || [];

  const booleanFilters = useMemo(() => {
    const booleanFiltersStringsArray = t(
      `app.filter.booleanFilters.${appConfig?.appId || "foodhelp"}`,
      { returnObjects: true }
    );

    return (
      appConfig?.filter?.booleanFilters?.map((item, idx) => {
        return {
          ...item,
          ...booleanFiltersStringsArray[idx],
        };
      }) || []
    );
  }, [appConfig?.filter?.booleanFilters, appConfig?.appId, t]);

  const initialSearchBufferDistance = appConfig?.search.searchInitialBufferDistance;

  const initialMapScale = 36111.909643; // LOD 14: https://developers.arcgis.com/documentation/mapping-apis-and-services/reference/zoom-levels-and-scale/

  const [initialCenter, setInitialCenter] = useState<Point>();
  const [initialExtent, setInitialExtent] = useState<Extent>();
  const [initialScale, setInitialScale] = useState<number>();
  const [mapReady, setIsMapReady] = useState<boolean>(false);
  const [currZone, setCurrZone] = useState<number | undefined>();

  const searchString = useLocation()?.search;
  const mView = new URLSearchParams(searchString).get("mView");

  const [errorMessage, setErrorMessage] = useState<ErrorTypes | undefined>();
  const [error, setError] = useState<unknown | undefined>();

  const [popupHeight, setPopupHeight] = useState<number>(0);

  const onError = (message?: ErrorTypes, error?: unknown) => {
    if (error) console.error(t("locations.error", { error: error }));
    setError(error || new Error(t("locations.unknownError")));
    setErrorMessage(message || ErrorTypes.UNKNOWN);
  };

  // trigger google translate re-translation if language is not set to English
  // called by this component on first render, and other components when they get new data that must be translated
  const updateGoogleTranslate = useCallback(() => {
    const lang = document.documentElement.lang;
    if (lang && lang !== "en") {
      document.querySelector(".goog-te-combo")?.dispatchEvent(new Event("change"));
    }
  }, []);

  useEffect(() => {
    updateGoogleTranslate();
  }, [updateGoogleTranslate]);

  const onSearchClear = () => {
    updateSearchTerm("");
    updateFocalPoint({});
    updateMapHighlight();
  };

  const onSearchComplete = ({ searchTerm, graphic, position }: OnSearchCompleteProps) => {
    updateSearchTerm(searchTerm);
    updateFocalPoint({
      graphic,
      position,
      isHidden: false,
    });
  };

  const onSearchBlocked = () => {
    onError(ErrorTypes.SEARCH_BLOCKED);
  };

  const onSearchError = (error: unknown) => {
    onError(ErrorTypes.SEARCH, error);
  };

  const onMapError = (error: unknown) => {
    onError(ErrorTypes.MAP, error);
  };

  const onListError = useCallback((error: unknown) => {
    onError(ErrorTypes.LIST, error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const switchView = () => {
    if (mView === "map") {
      // currently map, go to list
      navigate("");
    } else {
      // currently list, go to map
      navigate({ search: "?mView=map" });
    }
  };

  const onCloseDetails = () => {
    if (mView === "map") {
      navigate({
        pathname: "/locations",
        search: "?mView=map",
      });
    } else {
      navigate("/locations");
    }
    updateGoogleTranslate();
  };

  const goToMap = (loc: Graphic) => {
    const go = async () => {
      if (mapView) {
        navigate({
          pathname: "/locations",
          search: "?mView=map",
        });

        mapView.popup.close();

        await MapController.goToLocations({
          mapView,
          layerUrl: `${window.activeMapConfig.loc_layer_url}`,
          features: [loc],
          updateMapHighlight,
          objectIdFieldname: appConfig?.fields.locObjectIDFieldname || "FID",
          showPopup: true,
        });
      }
    };
    go();
  };

  const onClickCardLink = (objId: string) => {
    if (window.location.search === "?mView=map") {
      // if (mView === "map") {
      navigate({
        pathname: `/locations/${objId}`,
        search: "?mView=map",
      });
    } else {
      navigate(`/locations/${objId}`);
    }
  };

  const onPopupShownHidden = (event: PopupUpdateEvent) => {
    setPopupHeight(event.newHeight);
  };

  const onListItemHover: ListItemHoverHandler = (hoverEvent: ListItemHoverEvent) => {
    updateMapHighlight();

    if (hoverEvent.isHovering) {
      if (mapView && hoverEvent.location) {
        MapController.showHighlight({
          mapView,
          layerUrl: `${window.activeMapConfig?.loc_layer_url}`,
          features: [hoverEvent.location],
          pan: false,
          updateMapHighlight: updateMapHighlight,
        });
      }
    }
  };

  useEffect(() => {
    const zoomToExtent = async () => {
      const whereClause = appConfig?.locations ? appConfig?.locations.defaultExtentWhere : "1=1";
      const extent = await MapController.queryLayerExtent({
        featureLayer: locationsFeatureLayer,
        where: whereClause,
      });
      setInitialExtent(extent);
    };

    if (!mapReady && focalPoint === undefined) {
      const extentZoom = setTimeout(() => {
        zoomToExtent();
      }, 1500);

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (event) => {
            clearTimeout(extentZoom);
            setInitialCenter(Utils.geolocationToPoint(event));
            setInitialScale(initialMapScale);
            updateFocalPoint({
              position: event,
              isHidden: true,
            });
          },
          (error) => {
            clearTimeout(extentZoom);
            zoomToExtent();
          }
        );
      } else {
        clearTimeout(extentZoom);
        zoomToExtent();
      }
    }
  }, [focalPoint, locationsFeatureLayer, mapReady, updateFocalPoint, appConfig?.locations]);

  useEffect(() => {
    if (!mapReady) {
      const ready = focalPoint || (initialCenter && initialScale) || initialExtent;
      setIsMapReady(!!ready);
    }
  }, [focalPoint, initialCenter, initialScale, initialExtent, mapReady]);

  useEffect(() => {
    if (!zoneFL) {
      return;
    }
    if (focalPoint?.geometry === undefined) {
      setCurrZone(undefined);
    } else {
      const zoneFLQuery = zoneFL.createQuery();
      zoneFLQuery.geometry = focalPoint?.geometry as Geometry;
      zoneFLQuery.outFields = window.activeMapConfig?.zone_layer_out_fields;

      zoneFL.queryFeatures(zoneFLQuery).then((res) => {
        const values: number[] = [];
        const uniqueZones = res.features
          .map((item) => Number(item.attributes[zoneFLQuery.outFields[0]]))
          .filter((num) => {
            if (values.includes(num)) {
              return false;
            }
            values.push(num);
            return true;
          });
        if (uniqueZones.length === 1) {
          setCurrZone(uniqueZones[0]);
        } else {
          setCurrZone(undefined);
        }
      });
    }
  }, [focalPoint, zoneFL]);

  /**
   * Cooling Centers
   */
  const getBannerProps = () => {
    if (appConfig?.appId === "coolingcenters") {
      return emergency
        ? { isActive: true, message: t(`app.landing.bannerText.coolingcenters.active`) }
        : { isActive: false, message: t(`app.landing.bannerText.coolingcenters.default`) };
    }
    return undefined;
  };

  return (
    <StyledLocationsView>
      <div id="header-container">
        <HeaderBar
          title={alternateAppTitle ? alternateAppTitle : appTitle}
          deactivated={false}
          headerType="locations"
          logoFirst={appConfig?.appInfo.logoFirst}
          onHomeClick={() => {
            navigate("/");
          }}
          showBanner={getBannerProps()}
        >
          {sourceInfos && (
            <FinderSearch
              mapView={mapView}
              sourceInfos={sourceInfos}
              searchTerm={searchTerm}
              isLanding={false}
              onSearchClear={onSearchClear}
              onSearchComplete={onSearchComplete}
              onSearchBlocked={onSearchBlocked}
              onSearchError={onSearchError}
            />
          )}
        </HeaderBar>
        {`${appConfig?.appId}` === "hurricane" && (
          <Message zoneNumber={currZone} activeZoneNumber={evacZones} />
        )}
      </div>
      <div id="filter-row-wrapper">
        <StyledFilterH2 className="visually-hidden">Filters</StyledFilterH2>
        <Filter
          locationsFeatureLayer={locationsFeatureLayer}
          booleanFilters={booleanFilters}
          categoryFilters={filterFields}
          openHoursServiceTypes={locationLayerInfo.openHoursServiceTypes || []}
          openHoursTimeWindows={locationLayerInfo.openHoursTimeWindows || []}
          onFilterUpdated={updateFilterWhere}
          showOpenNowFilter={appConfig?.filter.showOpenNowFilter || false}
          updateGoogleTranslate={updateGoogleTranslate}
        />
      </div>
      {locationId && (
        // must put StyledDetailsWrapper here (or specifically the z-index and position styles)
        // otherwise the list & map will refresh when Details is opened
        <StyledDetailsWrapper>
          <Details
            key={uuidv4()}
            locationId={locationId}
            focalPoint={focalPoint}
            locationsFeatureLayer={locationsFeatureLayer}
            locationLayerInfo={locationLayerInfo}
            updateMapHighlight={updateMapHighlight}
            locationLayerUrl={window.activeMapConfig.loc_layer_url}
            zoneLayerUrl={window.activeMapConfig?.zone_layer_url}
            mapId={window.activeMapConfig.map_id}
            onClose={onCloseDetails}
            onZoomTo={goToMap}
            onCardClick={onClickCardLink}
            onPopupUpdate={onPopupShownHidden}
            detailSections={
              appConfig?.detailSections?.map((item, idx) => {
                const sectionStrings = t(`app.detailSections.${appConfig.appId}`, {
                  returnObjects: true,
                });
                if (item.serviceMinMaxValues?.length) {
                  item.serviceMinMaxValues = item.serviceMinMaxValues.map((m) => {
                    try {
                      m.fieldLabel = fieldLabels[m.serviceLabelFieldname] || "";
                    } catch (err) {
                      m.fieldLabel = "";
                      console.error(err);
                    }
                    return m;
                  });
                }
                const result = {
                  ...item,
                  ...sectionStrings[idx],
                };
                return result;
              }) || []
            }
            updateGoogleTranslate={updateGoogleTranslate}
            fieldLabels={fieldLabels}
            layoutDir={layoutDir}
          />
        </StyledDetailsWrapper>
      )}
      <div id="page-content">
        <div id="list-col-wrapper" className={!!mView ? "hide" : ""}>
          <StyledListH2 className="visually-hidden">Locations</StyledListH2>
          {appConfig?.list?.showCaveats && (
            <>
              <h3 className="visually-hidden">Additional Location Information</h3>
              <div id="caveats">
                <p>
                  {t(
                    `locations.firstCaveat.${appConfig.appId}.${isEmergency ? "active" : "default"}`
                  )}
                </p>
                <p>
                  {t(
                    `locations.secondCaveat.${appConfig.appId}.${
                      isEmergency ? "active" : "default"
                    }`
                  )}
                </p>
              </div>
            </>
          )}

          {booleanFilters.length !== 0 && (
            <BooleanFilterBanner booleanFilters={booleanFilters} isMapView={!!mView} />
          )}

          <List
            isMapView={!!mView}
            locationsFeatureLayer={locationsFeatureLayer}
            locationsFeatureLayerInfo={locationLayerInfo}
            filterWhere={filterWhere}
            focalPoint={focalPoint}
            onListLoadError={onListError}
            initialSearchBufferDistance={initialSearchBufferDistance}
            onListItemHover={onListItemHover}
            onCardClick={onClickCardLink}
            updateGoogleTranslate={updateGoogleTranslate}
            fieldLabels={fieldLabels}
          />
        </div>

        {mapReady && (
          <>
            <Map
              itemId={window.activeMapConfig?.map_id}
              initialScale={initialMapScale}
              initialCenter={initialCenter}
              initialExtent={initialExtent}
              targetLayerUrl={window.activeMapConfig?.loc_layer_url}
              zoneLayerUrl={window.activeMapConfig?.zone_layer_url}
              locationsFeatureLayerInfo={locationLayerInfo}
              onError={onMapError}
              onMapViewSet={updateMapView}
              locationGraphicsLayerId={MapController.SELECTED_LOCATION_LAYER_ID}
              onCardClick={onClickCardLink}
              onPopupUpdate={onPopupShownHidden}
              mainMap={true}
              updateGoogleTranslate={updateGoogleTranslate}
              fieldLabels={fieldLabels}
            />
          </>
        )}
        <StyledFabContainer>
          <CalciteFab
            icon={mView ? "list" : "map-pin"}
            text-enabled
            text={t(`locations.${mView ? "showList" : "showMap"}`)}
            color="blue"
            appearance="solid"
            onClick={switchView}
            style={mView ? { marginBottom: `${popupHeight}px` } : {}}
          ></CalciteFab>
        </StyledFabContainer>
        <ErrorComponent message={errorMessage} error={error} />
      </div>
      <Outlet />
    </StyledLocationsView>
  );
};

export default LocationsPage;
