import React, { useEffect, useMemo, useState } from "react";

import { I18nextProvider, useTranslation } from "react-i18next";
import instance from "../../../utils/i18n";
import { ServiceOption } from "../../shared";

import {
  StyledHoursSection,
  StyledSectionHeading,
  StyledServiceDropdown,
  StyledServiceNote,
  StyledTable,
} from "../Details-styled";
import { OperatingHoursSectionDetails } from "../Details.types";
import { formatHrs } from "../DetailsHelper";

import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const OperatingHoursSection = ({
  loc,
  locationLayerInfo,
  sectionTitle,
  disclaimer,
  layoutDir,
}: OperatingHoursSectionDetails) => {
  const { t, i18n } = useTranslation("components", { i18n: instance });

  const servicesAry = (locationLayerInfo.serviceTypeFieldNames || []).map((serviceType, ind) => {
    return {
      id: ind,
      name: serviceType,
      active: false,
    };
  });

  const [servicesActive, setServicesActive] =
    useState<{ id: number; name: string; active: boolean }[]>(servicesAry);

  //used to highlight StyledHoursRow(s) matching date
  const dayIndex = useMemo(() => {
    return new Date().getDay();
  }, []);

  useEffect(() => {
    // Collapse service hours by default
    setServicesActive(
      [...servicesActive].map((servAct) => {
        return {
          ...servAct,
          active: false,
        };
      })
    );
    // including servicesActive in this causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loc]);

  return (
    <I18nextProvider i18n={i18n}>
      <StyledHoursSection key={"opHours"}>
        <StyledSectionHeading>{sectionTitle}</StyledSectionHeading>
        {disclaimer && <StyledServiceNote id="hoursDisclaimer">{disclaimer}</StyledServiceNote>}
        {servicesActive?.length ? (
          servicesActive
            .filter((service) => {
              return loc.attributes[service.name] && loc.attributes[service.name] !== "";
            })
            .map((service) => (
              <div key={service.name}>
                <StyledServiceDropdown
                  aria-controls={"details-" + loc.attributes.GlobalID + "-" + service.id}
                  aria-expanded={service.active}
                  aria-label={t("details.hoursOfOperation", {
                    service: loc.attributes[service.name],
                  })}
                  onClick={() =>
                    setServicesActive(
                      [...servicesActive].map((servAct) => {
                        if (service.id === servAct.id) {
                          return {
                            ...servAct,
                            active: !servAct.active,
                          };
                        } else {
                          return servAct;
                        }
                      })
                    )
                  }
                >
                  <ServiceOption label={loc.attributes[service.name]}></ServiceOption>
                  {service.active ? (
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      style={{ fontSize: 13, color: "var(--lib-secondary-gray)" }}
                    ></FontAwesomeIcon>
                  ) : (
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      style={{ fontSize: 13, color: "var(--lib-secondary-gray)" }}
                    ></FontAwesomeIcon>
                  )}
                </StyledServiceDropdown>
                {service.active && locationLayerInfo.openHoursServiceTypes && (
                  <StyledTable
                    id={"details-" + loc.attributes.GlobalID + "-" + service.id}
                    aria-hidden={!service.active}
                  >
                    <tbody>
                      {formatHrs(
                        loc,
                        locationLayerInfo.openHoursServiceTypes[service.id],
                        dayIndex,
                        layoutDir,
                        t
                      )}
                    </tbody>
                  </StyledTable>
                )}
                {service.active && locationLayerInfo.serviceTypeNotes && (
                  <StyledServiceNote>
                    {loc.attributes[locationLayerInfo.serviceTypeNotes[service.id]]}
                  </StyledServiceNote>
                )}
              </div>
            ))
        ) : (
          <StyledTable id={"details-" + loc.attributes.GlobalID} aria-hidden={false}>
            <tbody>
              {formatHrs(
                loc,
                locationLayerInfo?.openHoursServiceTypes?.length
                  ? locationLayerInfo?.openHoursServiceTypes[0]
                  : "",
                dayIndex,
                layoutDir,
                t
              )}
            </tbody>
          </StyledTable>
        )}
      </StyledHoursSection>
    </I18nextProvider>
  );
};

export default OperatingHoursSection;
